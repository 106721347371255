import React from "react"
import { Layout, NewsletterSection } from "@components/layout"
import { ContactForm } from "@components/standard"
import {
  HomeAttentionSection,
  HomeSection,
  HomeInfoSection,
  HomeOffer,
} from "@components/standard"

const IndexPage = ({ pageContext, location }) => {
  console.log(pageContext)
  const { page } = pageContext
  return (
    <Layout {...page}>
      <HomeAttentionSection />
      <HomeSection />
      <HomeInfoSection />
      <HomeOffer />
      <ContactForm />
      {/* <NewsletterSection /> */}
    </Layout>
  )
}

export default IndexPage
